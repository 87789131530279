import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | CoinbidexSwap',
  defaultTitle: 'CoinbidexSwap',
  description:
    'The most trusted decenteralized exchange platform powered by CoinbidexSwap ecosystem.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@SwapOceanDex',
    site: '@SwapOceanDex',
  },
  openGraph: {
    title: 'CoinbidexSwap - A next evolution DeFi exchange on BNB Smart Chain (BSC)',
    description:
      'The most trusted decenteralized exchange platform powered by CoinbidexSwap ecosystem.',
    images: [{ url: 'logo.png' }],
  },
}
